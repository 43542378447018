@font-face {
    font-family: 'pnpicons';
    src: 
         url('/static/css/fonts/pnpicons.woff2?87062852') format('woff2'),
         url('/static/css/fonts/pnpicons.woff?87062852') format('woff');
    font-weight: normal;
    font-style: normal;
  }

 .fa {
    font-family: "pnpicons";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
   
  }
   
  .fa-close::before { content: '\e800'; } /* '' */
  .fa-ok::before { content: '\e801'; } /* '' */
  .fa-right-open::before { content: '\f006'; } /* '' */
  .fa-left-open::before { content: '\f007'; } /* '' */
  .fa-play-circle::before { content: '\f144'; } /* '' */
  .fa-search::before { content: '\f002'; } /* '' */