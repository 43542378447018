@keyframes underline-hover {
    0% {
        width: 0%;
    }
    100% {
        width:100%;
    }
}

#pnp__header-navigation {
    height: rem-calc(80);
    border-bottom: 1px solid $light-gray;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1010;
    background: $white;
    box-shadow: 0px 5px 10px rgba(138,138,138,0.3);

    .grid-x {
        height: rem-calc(80);
    }

    .pnp__menu-toggle {
        i {
            margin-right:0.3rem;
            display: inline-block;
        }
    }

    @include breakpoint(medium only) {
        height: rem-calc(120);
        padding-bottom: 0.1rem;
    }
}
#pnp__logo-container {
    height: rem-calc(80);
    padding-top: rem-calc(20);
    padding-bottom: rem-calc(20);

    @include breakpoint(medium only) {
        height: rem-calc(72);
    }

    .pnp__logo {
        height: 1.5rem;
        margin-top: 0.5rem;
    }

    a {
        display: block;
    }
}
@include breakpoint(small only) {
    #pnp__header-navigation {
        padding: 0 1.375rem ;
    }
    #pnp__header-navigation.open {
        z-index: 1110;
        padding: 0;
    }

    .pnp__main-nav {
        transform: scale(1.2);
        transition: transform 0.2s linear;
        height: 0;
        overflow: hidden;

        li {
            padding: 0.5rem;
            transition: padding 0.4s ease-in-out;
        }

        &.open {
            overflow: visible;
            height: auto;
            position: fixed;
            top: 0;
            bottom: 0px;
            min-height: 100%;
            transform: scale(1);
            opacity: 1;
            padding: 0;
            background-color: rgba(255,255,255,1);
            padding-top: 80px;
            transition: transform 0.3s linear;
            z-index: 1012;
            overflow: auto;

            .pnp__main-navigation {
                list-style: none;
                background: #fefefe;
                position: relative;
                z-index: 4;
                padding-bottom: 0.5rem;
                min-height: 100%;
                margin: 0;
                padding-bottom: 4rem;
                font-size: 90%;

                li {
                    padding: 1rem;
                    border-bottom: 3px solid transparent;
                    overflow: auto;
                    left: 0;
                    right: 0;
                    text-align: center;

                    a {
                        font-size: 1.2rem;
                    }
                }
            }
        }

        @include breakpoint(medium) {
            display: block;
            height: auto;
            position: relative;
        }
    }
}
.pnp__main-navigation {
    font-size: 90%;
    list-style:  none;
    background: $white;
    position: relative;
    z-index: 4;
    margin: 0 -0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid $light-gray;

    @include breakpoint(medium) {
        display: block;
    }

    li {
        padding: 0.3rem 1rem;
        border-bottom: 3px solid transparent;

        a {
            color: $body-font-color;
            font-weight: normal;
            white-space: nowrap;
            position: relative;
        }

        &.active {
            border-bottom-color: yellow;

            a {
                font-weight: bold;
            }
        }

        @media (hover: hover) {
            &:not(.active,:last-child) a:hover::after {
                position: absolute;
                width: 0%;
                content: '';
                height: 3px;
                background: $light-gray;
                left: 0;
                bottom: -8px;
                animation: underline-hover 0.3s ease-in;
                animation-fill-mode: forwards;
            }
        }
    }

    li:last-child {
        a {
            padding: 0.6rem;
            background-color: $button-blue-color;
            color: $white;
            margin-bottom: 0;
            border-radius: 7px;
            box-shadow: 0px 3px 5px rgba($dark-gray, 0.4);
            transition: box-shadow 0.2s ease-in-out;

            &:hover {
                box-shadow: 0px 2px 2px rgba($dark-gray, 0.4);
            }
        }
    }

    @include breakpoint(medium) {
        justify-content: space-between;
        margin:  0;
        background: none;
        display: flex;
        padding: 0;
        border: none;

        li {
            padding: 0;
            line-height: rem-calc(30);
        }


        li:last-child {
            padding: 0;
            margin-right: 0;
        }
    }
}


.pnp__menu-toggle {
    position: fixed;
    right: 2rem;
    top:28px;
    z-index: 1210;
    width:26px;
    height: 40px;

    &.open .pnp__menu-inner {
        transition-delay: .12s;
        transition-timing-function: cubic-bezier(.215,.61,.355,1);
        transform: rotate(45deg);

        &::before {
            top: 0;
            transition: top 75ms ease,opacity 75ms ease .12s;
            opacity: 0;
        }

        &::after {
            bottom: 0;
            transition: bottom 75ms ease,transform 75ms cubic-bezier(.215,.61,.355,1) .12s;
            transform: rotate(-90deg);
        }
    }
}
.pnp__menu-inner {
    position: absolute;
    width: 26px;
    height: 3px;
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform;
    border-radius: 3px;
    background-color: $body-font-color;
    top:8px;
    left:0;

    &::before,
    &::after {
        position: absolute;
        width: 26px;
        height: 3px;
        transition-timing-function: ease;
        transition-duration: .15s;
        transition-property: transform;
        border-radius: 3px;
        background-color: $body-font-color;
        content: '';
        left:0;
    }

    &::before {
        top: -8px;
        transition: top 75ms ease .12s,opacity 75ms ease;
    }

    &::after {
        bottom: -8px;
        transition: bottom 75ms ease .12s, transform 75ms cubic-bezier(.55,.055,.675,.19);
    }
}