.page-section .pnp__case-study-item-container {
    padding-top: 2rem;
    margin-bottom: 0;
} 

.pnp__case-study-item-container {
    list-style: none;

    .cell {
        margin-bottom: 2rem;
    }

    .pnp__case-study-item {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        border-width: 3px;
        border-style: solid;
        border-radius: 2rem;
        padding: 5rem 4rem 5rem;
        color: $white;
        justify-content: center;
        height: 100%;
        background-size: cover;

        &:hover > div {
            text-decoration: underline;
        }

        > * {
            width: auto;
        }
        
        p {
            margin-bottom: 3rem;
        }

        > div {
            margin-top: auto;
            color:#3ffcc1; 
        }
    }
}