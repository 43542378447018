.pnp__image-grid-container {
    padding-top: 2rem;
    padding-bottom: 2rem;
    
    .pnp__image-grid {
        list-style: none;
        
        li {
            padding:1rem 2rem;
            border-radius: 2rem;
            height: rem-calc(150);
            margin-bottom: 2rem;
            display: flex;
            
            img {
                height: auto;
                width: 100%;
                align-self: center;
            }
        }
    }
}