.pnp__half-content-image {
    padding: 5rem 0 0;
    background-size: auto 45vh;
    background-repeat: no-repeat;
    background-position: center bottom;
    
    h1 {
        font-size: 2.2rem;
        line-height: 1.2;
        margin-bottom: 1em;
    }
    
    p {
        font-size: 1.2rem;
    }
    
    .pnp__button-container {
        padding-top: 2.3rem;
        padding-bottom: 2.3rem;
        
        .button {
            font-size: 1.25rem;
        }
    }
    
    .pnp__half-hero-image {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .pnp__half-content-image-inner {
        p {
            line-height: 1.4;
            font-size: 1.1rem;
        }
    }
    
    @include breakpoint(medium) {
        padding: 2rem 0;
        background-size: contain;
        background-origin: content-box;
        
        h3 {
            font-size: 3rem;
        }
        
        p {
            font-size: 1.4rem;
        }
        
        .pnp__half-hero-image {
            padding: 2rem;
        }
        
        .pnp__half-content-image-inner {
            padding-top: rem-calc(100);
            padding-bottom: rem-calc(100);
        }

        .grid-x {
            height: 100%;
        }
        
        &.right {
            background-position: left center;
            
            .pnp__half-content-image-inner {
                text-align: right;
            }
        }
        
        &.left {
            background-position: right center;
        }
        
        p {
            font-size: 1.2rem;
        }
    }
}
.pnp__thank-you-half {
    h2 {
        margin-bottom: 3rem;
    }
    .button {
        font-size: 1.5rem;
        border-radius: 100px;
    }
}