.pnp__filterable-cell {
    text-align: center;
    padding: 6rem 10%;
    color: $white;
    border-radius: 2rem;
    background-size: cover;
    opacity: 0.9;
    margin-bottom: 1rem;
    
    h3 {
        font-size: 2rem;
        margin-bottom: 0.8rem;
    }
    
    p {
        font-size: 1.3rem;
        line-height: 1.15;
    }
    
    &:hover,
    &:active {
        color: $white;
        opacity: 1;
    }

    &.programs {
        margin-bottom: 2rem;
    }
}
.pnp__filters {
    overflow: scroll;
    white-space: nowrap;

    @include breakpoint(medium) {
        overflow: unset;
        white-space: normal
    }
}
.pnp__filter-bar {
    padding: 3.5rem 0;
    font-size: 0.9rem;
    text-transform: uppercase;
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;

    span {
        display: inline-block;
        margin-right: 0.5rem;
    }
    
    .pnp__filter {
        border-radius: 0.5em;
        padding: 3px 1em;
        color: $white;
        cursor: pointer;

        margin-bottom: 0.5rem;
        
        &.gray {
            background-color: $medium-gray !important;
        }
    }
}
.pnp__filter-item-container {
    margin-bottom: 2rem;
}