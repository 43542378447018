@keyframes slideLinkMenu {
    0% {
        bottom: -100px;
    }
    100% {
        bottom: 50px
    }
}

.pnp__useful-links {
    position: fixed;
    bottom: -100px;
    right: rem-calc(30);
    animation: slideLinkMenu 2s;
    animation-delay: .65s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    z-index: 101010;
    display: none;

    @include breakpoint(medium) {
        display: block;
    }

    &.open {
        nav {
            display: block;
        }

        .pnp__ul-close-button {
            display: block;
            line-height: 58px;
        }

        .pnp__useful-links-text {
            display: none;
        }

        .pnp__useful-links-label {
            padding: 0;
        }
    }

    nav {
        display: none;
        background: #fff;
        position: absolute;
        bottom: -5px;
        right: -5px;
        border-radius: 2rem;
        box-shadow: 0px 3px 10px rgba(40,40,40,0.15);
        padding-bottom: rem-calc(70);
        min-height: rem-calc(250);
        
        ul {
            margin: 2rem 0 0;
            list-style: none;
            
            li {
                white-space: nowrap;
        
                a {
                    color: $body-font-color;
                    font-size: 0.9rem;
                    display: block;
                    width: 100%;
                    padding-right: rem-calc(70);
                    padding: 0.5rem 5rem 0.5rem 1.5rem;

                    &:hover {
                        background-color: #f8f8f8;

                    }
                }
            }
        }
    }
    
    .pnp__useful-links-label {
        width:rem-calc(60);
        line-height: 1.2;
        padding: 1.1rem 0;
        text-align: center;
        height:rem-calc(60);
        border-radius: 50%;
        text-transform: uppercase;
        font-size: 0.65rem;
        color: $white;
        background-color: #27648f;
        background-image: linear-gradient(315deg, #1569db, #a1aeff);
        box-shadow: 0px 2px 6px rgba(40,40,40,0.3);
        cursor: pointer;
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        transition: box-shadow 0.25s ease-out;

        &:hover {
            box-shadow: 0px 1px 1px rgba(40,40,40,0.3);
        }
    }

    .pnp__ul-close-button {
        font-size: 1.7rem;
        margin-top: 0.2rem;
        display: none;
    }
}