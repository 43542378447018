.pnp__hubspot-mail-form {

    .hs-email {
        width: 65%;
    }

    form {
        display: flex;
        flex-wrap: wrap;
        max-width: 870px;
        margin: auto;

        > div {
            width: auto;
            flex-grow: 1;
            padding: 0 1rem;

            > label {
                display: none;
            }
        }
    }

    ul.no-list.hs-error-msgs.inputs-list {
        list-style: none;
        margin: 1rem 1rem 0;
        color: darkgray;
    }

    input[type="submit"] {
        border: 2px solid var(--border-color);
        color: var(--color);
        background-color: var(--background-color);
        width:100%;
        border-radius: 0.5rem;
        font-weight: bold;
        padding: 1rem;
        line-height: 1;
    }

    .hs-email input {
        border-radius: 2rem;
        border: 0;
        padding: 1.6rem;
        box-shadow: 0px 2px 10px rgba(50,50,50,0.15);
        font-size: 1.15rem;
        margin-bottom: 0;
    }

    .legal-consent-container {
        order: 2;
        margin: 2rem 0 0;
    }

}
