.pnp__text-hero {
    min-height: 0;
    
    h1 {
        font-size: 2.4rem;
        line-height: 1em;

        @include breakpoint(medium) {
            font-size: 4rem;
        }
        

        &::before {
            content: '';
            display: block;
            height: 8px;
            width: 50px;
            border-radius: 4px;
            margin-bottom: 1rem;
            background-color: rgba(136, 5, 5, 1);
            background-color: var(--line-color);
        }
    }
}