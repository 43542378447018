#pnp__startup-index-page div:first-child > .pnp__startup-item-container {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

#pnp__startup-index-page.pnp__more-size {
    max-width: 83.5rem!important;
}

.pnp__startup-item-container {
    list-style: none;
    background-color: #f4f4f4;
    padding: 1rem 0.5rem;
    border-radius: 2rem;
    margin: 0;
    margin-bottom: 2rem;
    
    h3 {
        font-size: 1.3rem;
    }

    li  > div {
        background: $white;
        border-radius: 2rem;
        padding: 1.2rem 1rem;
        display: flex;
        position: relative;
        cursor: pointer;
        flex-wrap: wrap;
        justify-content: center;
        height: 100%;
        align-items: flex-start;
    }
    
    @include breakpoint(small only) {
        li > div {
            border-radius: 2rem;
            padding: 1.2rem 1rem;
            display: flex;
            position: relative;
            cursor: pointer;
            flex-wrap: wrap;
            justify-content: center;
            height: 100%;
            align-items: center;
            flex-direction: column;
        }
        .pnp__startup-item-name {
            padding-left: 0rem;
        }

    }

    @include breakpoint(medium) {
        padding: 2rem;
        
        li > div {
            flex-wrap: nowrap;
            // padding: 2rem 2rem;
            justify-content: unset;
            height: 100%;
            align-items: center;

            /*
             border-radius: 2rem;
        padding: 1.2rem 1rem;
        display: flex;
        position: relative;
        cursor: pointer;
        flex-wrap: wrap;
        justify-content: center;
        height: 100%;
        align-items: center;
        flex-direction: column; */
        }

        .pnp__startup-item-name {
            padding-left: 0.5rem;
        }

        .pnp__startup-meta.list {
            position: absolute;
            right:1.4rem;
            bottom: 1rem;
        }
    }
    
    .pnp__startup-item-image {
        min-width: 60x;
        width: 60px;
        height: 60px;
        border: 1px solid #E0E0E0;
        border-radius: 10px;
        padding: 0.4rem;
        display: flex;
    }
    
    .pnp__startup-item-name {
        font-size: 0.9rem;
    }

    .pnp__startup-item-details {
        display: none;
    }

    .pnp__unicorn-item-details {
        display: none!important;
    }

    .pnp__pad-h {
        padding-bottom: 1.1rem;
        padding-top: 1rem;
        margin-bottom: 1rem;
    }
    .pnp__carousel-arrow {
        font-size: 1rem;
        height: 3rem;
        padding: 0.9rem 0;
        top: calc(50% - 1rem);
        width: 3rem;
    }
    .pnp__carousel-arrow[data-direction="left"] {
        left: 0px;
    }

    .pnp__carousel-arrow[data-direction="right"] {
        right: 0px;
    }
}

.pnp__link-item-details {
    margin-left: auto;
    margin-top: auto;
    padding-bottom: 0.8rem;
}


.pnp_unicorn-width {
    
    width: 80%;
    a {
        float: right;
    }
    @include breakpoint(small only medium only) {
        width: 65%;
    }
}



.pnp__close-button {
    text-align: right; 

    button {
        border: 1px solid #c0c0c0;
        border-radius: 4px;
        color: gray;
        font-size: 1.2rem;
        padding: 1px 3px;
        cursor: pointer;
    }
}

.pnp_no-padding-top-bottom {
    padding-bottom: 0px!important;
    padding-top: 0px!important;
}

.pnp__filter-header {
    background: #f4f4f4;    
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    padding: 2.7rem 2rem 2rem;
    margin: 1rem 0 2rem;
    
    .label {
        border-radius: 0.75rem;
        text-transform: uppercase;
        font-size: 0.9rem;
        padding: 0.5rem 0.9rem 0.6rem;
        margin-right: 0.9rem;
        margin-bottom: 0.7rem;
        cursor: pointer;
        user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        -moz-user-select: none;
        
        &.gray {
            background: $medium-gray !important;
        }
        
        &:last-child {
            margin-right: 0;
        }
    }
    
    .filter-program-header {
        text-transform: uppercase;
        margin-bottom: 1rem;
    }
}
.filterable-sub-filters {
    margin-top: 1rem;
}
.pnp__startup-meta {
    &.list {
        margin-top: auto;

        @include breakpoint(small only) {
            width: 100%;
            margin-top: .75rem;

            &:empty {
                display: none;
            }
        }

        .label {
            font-size: 0.7rem;
        }
    }
    &.pnp__padd-left-modal {
        padding-left: 0.2rem!important;    
    }

    @include breakpoint(small only medium only) {
        text-align: center!important;
    }
    
    
    .label {
        border-radius: 1.5rem;
        font-size: 0.7rem;
        margin-right: 0.3rem;
        padding: 0.3rem 0.7rem;
        text-transform: uppercase;

        @include breakpoint(medium) {
            margin-right: 0;
            margin-left: 0.5rem;
        }
    }

    .pnp__startup-meta-exit {
        background-image: linear-gradient(270deg, #6c6cd0 0%,  #6c6cd0 100%);
    }

    .pnp__startup-meta-portfolio {
        background-image: linear-gradient(270deg, #4a8df7 0%,  #4a8df7 100%);
    }

    .pnp__startup-meta-unicorn {
        background-image: linear-gradient(270deg,  #f16b65  0%, #f16b65 100%);
    }
}

.pnp__startup-meta-finder {
    background-image:  linear-gradient(270deg, #C0C0C0 0%, #C0C0C0 100%);
    border-radius: 1.5rem;
    font-size: 0.7rem;
    margin-right: 0.3rem;
    padding: 0.3rem 0.7rem;
    text-decoration: none!important;
}

.pnp__startup-description {
    background: white;
    border-radius: 1rem;
    margin-bottom: 1rem;
}
.pnp__startup-item-image {
    align-items: center;
    background-color: #fff;
    border: 1px solid #E0E0E0;  /** He cambiado esto **/
    border-radius: 10px;  /** He cambiado esto **/
    display: flex;
    height: 60px;  /** He cambiado esto **/
    padding: 0.5rem;
    width: 60px;  /** He cambiado esto **/
}
.pnp__startup-item-name {
    color: black;
    margin-top: .5rem;
    padding: 0;
    text-align: center;
    width: 100%;

    @include breakpoint(medium) {
        font-size: 1.2rem;
        margin-top: 0px;
        padding: 0 0.7rem;
        text-align: left;
        width: auto;
    }

    @include breakpoint(small only medium only) {
        padding-left: 1rem;
        text-align: left;
    }
}

.pnp__startup-item-header {
    margin-bottom: 1rem;
    align-items: center;
    display: flex;
    font-size: 0.9rem;
    justify-content: left;

    .pnp__startup-meta {
        display: block;
    }


}

.pnp__startup-links {
    padding: 0;
    list-style-type: none;

    li {
        margin-bottom: 1.5rem;
    }

    a, button {
        padding: 1.5rem;
        text-align: center;
        line-height: 1rem;
        display: block;
        border-radius: 0.5rem;
        width: 100%;
        color: white;
        box-shadow: 0px 3px 4px rgba(40,40,40,0.10);
        font-size:0.9rem;
        font-weight: bold;
        transition: box-shadow 0.25s ease-out;
        cursor: pointer;

        &:hover {
            box-shadow: 0px 1px 3px rgba(40,40,40,0.10);
        }
    }

    button {
        background-color: #abc1c7;
    }

    .pnp__startup-website-link {
        background-color: white;
        color: #2d9fbb;
        text-align: left;
        
        i {
            float: right;
        }
        
    }
}

.pnp__url-to-hide {
    display: none;
}

@include breakpoint(small only medium only) {
    .pnp__url-to-hide {
        display: inline;
    }
    .pnp__url-to-show {
        display: none;
    }

    .pnp__tags-to-hide {
        display:none !important; 
    }
}

.pnp__pad-header{
    padding:0 2rem;

    hr {
        margin: 2rem 0rem 2.5rem 0rem;
    }
}

.pnp__filterDiv {
    align-items: center;
    display: flex;
    font-size: 0.9rem;
    height: 3rem;
    justify-content: left;
    
    input, select {
        border: none;
        border-radius: 10px;
        box-shadow: 0px 0.5rem 0.5rem #e6e6e6;
        height: 48px;
        margin-right: 10px;
        margin-top: 0.5rem;
        transition: box-shadow 0.2s ease-in;
        width: 300px;
    }

    select {
        border: none;    
        font-size: 0.9rem;
        height: 48px;
        line-height: 33px;
        width: 200px;
    }
    
    span {
        cursor: pointer;
        margin-left: auto;
        text-decoration: underline;
    }

    @include breakpoint(small only medium only) {
        flex-direction: column;
        height: auto;
        span {
            margin-left: auto;
            margin-right: auto;
        }
        select {
            width: 300px;
        }
        .pnp__div-order-by {
            margin-left:0
        }
    }
}

.pnp__property-block {
    font-size:0.9rem;
    
    div {
        display:block;
    }
}

.pnp__unicorn-flex-box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    @include breakpoint(small only medium only) {
        width: 225px!important;
        p {
            font-size: 0.9rem!important;
        }
    }
   
}

.pnp__size-grid {
    width: 25%!important;
    @include breakpoint(small only medium only) {
        width: 50%!important;
    }
}

.pnp_unicorn-header {
    padding: 0 2rem;
    margin-bottom: 1rem;
    margin-top: 3rem;
}

.pnp_font-size-startups-header {
    font-size: 1.1rem!important;
}

#wordToSearch {
    display: none;
}

#wrappedWords {
    
    margin-top: -5px;
    position: absolute;

    @include breakpoint(small only medium only) {
        margin-top: -10px;
        position: relative;
    }
}


.pnp__border-background {
    border-radius: 0!important;
}

.pnp__pagination {
    align-content: center;
    align-items: center;
    display: flex;
    justify-content: center;

    a {
        text-decoration: none;
        color: inherit;
    }
}

.pnp__page-item {
	background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E0E0E0;
    border-radius: 10px;
	color:#0f0f0f;
	cursor:pointer;
	display:inline-block;
    height: 35px;
    margin-left: 3px;
    opacity: 1;
	padding:5px 7px;
    text-align: center;
	text-decoration:none;
    text-shadow:0px 1px 35px #2b665e;
    width: 35px;
}
.pnp__page-item:hover {
	background-color:#dae7e7;
}
.pnp__page-item:active {
	color:black;
}

#startupList {
    padding-left: 0!important;
    padding-right: 0!important;
    padding-top: 0!important;
}

.pnp__dropdown-order {
    background: inherit!important;
    background-color: inherit!important;
    border: none!important;
    box-shadow: none!important;
    cursor:pointer;    
    margin-left: 0;
    margin-right: 0;
    max-width: 125px;
    text-decoration: underline!important;
    padding-left: 0;
    padding-right: 0;
}

.pnp__dropdown-order:hoover {
    background-color: transparent!important;
}

#sortByIdSpan {
    color: black;
}

.pnp__div-order-by {
    margin-left:auto;
}

.pnp__startup-item{
    .lazy {
        opacity:1!important
    }
}

#orderByDivId {
    margin-right:0px!important;
}

.pnp__unicorn-image-top {
    display:block; 
    margin-bottom: auto; 
    margin-top: auto; 
    max-width:100%; 
    max-height:100%; 
    padding: 20px;
}

.pnp__text-unicorn{
    padding-left: 20px;
    padding-right: 20px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical; 
}

#labelToSearchId {
    position: relative;
}
#labelToSearchId:before {
    content: "";
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    width: 20px;
    fill: grey;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 25 25' fill-rule='evenodd'%3E%3Cpath fill='LightGray' d='M16.036 18.455l2.404-2.405 5.586 5.587-2.404 2.404zM8.5 2C12.1 2 15 4.9 15 8.5S12.1 15 8.5 15 2 12.1 2 8.5 4.9 2 8.5 2zm0-2C3.8 0 0 3.8 0 8.5S3.8 17 8.5 17 17 13.2 17 8.5 13.2 0 8.5 0zM15 16a1 1 0 1 1 2 0 1 1 0 1 1-2 0'%3E%3C/path%3E%3C/svg%3E") center / contain no-repeat;
}
