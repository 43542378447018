#pnp__main-footer {
    background-color: #f8f8f8;
    padding: 2rem;

    @include breakpoint(medium) {
        padding: 2rem 0;
    }

    margin-top: 1rem;

    .pnp__logo {
        height: 1.5rem;
    }

    .iso__logo {
        height: 8rem;
    }

    ul {
        margin: 0;
        margin-top: 1.2rem;
        list-style: none;

        a {
            color: $body-font-color;
            font-size: 1.2rem;
        }
    }

    h4 {
        text-transform: uppercase;
        font-size: 1.4rem;
    }

    .grid-x {
        margin-top: 2.5rem;
        margin-bottom: 3rem;
    }

    .pnp__footer-cell {
        margin-bottom: 2rem;
    }

    @include breakpoint(medium) {
        margin-bottom: 0;
    }
}