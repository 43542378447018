.pnp__button-section {
    padding-top: 5rem;
    padding-bottom: 5rem;
    
    .pnp__button-heading {
        width: 80%;
        margin: 0 auto rem-calc(40);

        @include breakpoint(medium) {
            width: 42.5%;
        }

        h2 {
            margin-bottom: 0;
            font-size: 1.9rem;
        }
    }

    a.button:not(.hollow) {
        margin-bottom: 0;
        font-size: 1.2rem;
        box-shadow: 0px 0.3em 0.5rem $medium-gray;
    }
}