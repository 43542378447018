.pnp__team-member-page {
    padding: 1.5rem;
    background-color: #f0f0f0;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    padding-bottom: 2rem;

    .pnp__startup-item-header {
        margin-bottom: 2rem;
    }
    .pnp__startup-description {
        font-size: 1.2rem;
        padding: 35px;
    }

    .pnp__startup-item-image {
        padding: 0;
        overflow: hidden;
        width: 150px;
        height: 150px;

        @include breakpoint(medium) {
            margin-right: 1.5rem;
        }

        &.company {
            img {
                height: 100%;
                object-fit: contain;
            }
            padding:1rem;
        }
    }

    .pnp__startup-item-name {
        h1 {
            font-size: 2rem;
            margin-bottom: 0px;
        }
        
        p {
            font-size: 1.4rem;
        }
    }

    @include breakpoint(medium) {
        padding: 3rem;
    }

    @include breakpoint(small only) {
        .pnp__startup-item-header {
            justify-content: center;
        }
    }
}
.pnp__team-member-related {
    margin-top: 4rem;
    background-color: #f0f0f0;
    margin-bottom: 2rem;

    .pnp__link-collection-header {
        text-align: left;
        max-width: none;
        padding: 5rem 0 2.5rem;
    }
}
.pnp__team-collection {
    padding: 3rem 0 4rem;
    
    .pnp__link-collection-item {
        text-align: left;
    }

    .pnp__team-item-content {
        padding: 1rem 2rem;
    }

    img {
        border-top-left-radius: 2rem;
        border-top-right-radius: 2rem;
    }

    a {
        color: $body-font-color;
    }
}
.pnp__team-filter {
    margin-top: -1rem;
    background: white;
    margin-bottom: 4rem;
}