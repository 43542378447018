.pnp__stat-collection-container {
    padding: 3rem 0;

    @include breakpoint(medium) {
        padding: 0 0 8rem 0;
    }
}
.pnp__stat-collection {
    margin: 0;
    list-style: none;

    li {
        margin-bottom: 1rem;
    }
}
.pnp__stat-collection-item { 
    padding: 2rem 1.5rem;
    border-radius: 2rem;
   
    .pnp__stat-value {
        font-size: 3rem;
        line-height: 1;
        margin-bottom: 1rem;
    }
    
    .pnp__stat-label {
        line-height: 1.2;
        font-size: 1.25rem;
    }

    @include breakpoint(medium) {
        .pnp__stat-label {
            width: 70%;
        }
    }
}