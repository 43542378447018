.page-section.company_collection,
.page-section.startup_collection {
    padding: 7rem 0;
    margin-bottom: 3rem;
}
.pnp__company-collection {
    header {
        width: 70%;
        margin: auto;

        @include breakpoint(medium) {
            width: 45%;
        }
    }

    h2 {
        font-weight: bold;
    }

    ul {
        list-style: none;
        margin-top: 2rem;
    }
}
.pnp__company-collection-item { 
    margin-bottom: 2rem;

    .pnp__company-collection-inner {
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 1.5rem;

        img {
            object-fit: contain;
            width: auto;
            height: auto;
            max-height: 100%;
        }

    }

    > div {
        height: 150px;
        background: #fff;
        border-radius: 2rem;
    }
}