.button {
    font-weight: 600;

    @include breakpoint(small only) {
        padding: 0.8rem;
    }
}
.button.blue {
    @include button(
      $expand: false,
      $background: $button-blue-color,
      $background-hover: $button-blue-color,
      $color: #fff,
      $style: solid
    )
}
.button.grey.hollow {
    @include button(
      $expand: false,
      $background: transparent,
      $background-hover: transparent,
      $color: $black,
      $style: solid
    );
    
    border-width: 2px;
    border-color: #6d6d6d;
    transition: border-color 0.25s ease-out, color 0.25s ease-out;

    &:hover {
        color: $button-blue-color !important;
        border-color: $button-blue-color;
    }
}
.button:not(.hollow) {
    box-shadow: 0px 3px 5px rgba($dark-gray, 0.4);
    transition: box-shadow 0.2s ease-in-out;

    &:hover {
        box-shadow: 0px 2px 2px rgba($dark-gray, 0.4);
    }
}

.button.round {
    border-radius: 2em;
}
.button.hollow {
    background-color: $white;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    
    &:hover {
        background-color: var(--color) !important;
        color: white !important;
    }
}
.slimmed.button {
    margin-bottom: 0;
    padding: 0.65rem 1.75rem;
}
.translucent.round.black {
    border-color: $white;
    color: $white;
    background-color: rgba($black, 0.45);
}
body {
    letter-spacing: -0.5px;
    padding-top: rem-calc(80);
    
    @include breakpoint(medium only) {
        padding-top: rem-calc(120);
    }
}
body > main {
    min-height: 90vh;
}
.button .fa {
    font-size: 2em;
    vertical-align: middle;
    margin-right: 0.5rem;
    line-height: 0;
    margin-top: -0.1em;
}
.relative {
    position: relative;
}
.lazy {
    //opacity: 0;
    transition: opacity 0.5s;
    display: block;
    width: 100%;

    &.loaded {
        opacity: 1;
    }
}
.pnp__card-shadow {
    box-shadow: 0px 0.5rem 0.5rem $light-gray;
    transition: box-shadow 0.2s ease-in;

    &:hover {
        box-shadow: 0 0.2rem 0.2rem $light-gray;
    }    
}
.facebook-background {
    background-color: #3b5998;
}
.twitter-background {
    background-color: #1da1f2;
}
.linkedin-background {
    background-color: #0077b5;
}

.sr {
    .pnp__link-collection-item,
    .pnp__masonry-item-container,
    .pnp__stat-collection-item, 
    .pnp__half-hero-image,
    .page-section:not(:first-child) .pnp__hero-block {
        visibility: hidden;
    }

    .nav+.page-section .pnp__hero-block {
        visibility: visible;
    }
}

h1, h2, h3 {
    letter-spacing: -1px;
}


.snippet_block {
    .pnp__link-collection-header + .snippet-section .pnp__link-collection {
        padding-top: 0;
    }
}

.page-section.image {
    margin-bottom: 2rem;
}