.snippet-section {
    overflow: hidden;
}
.pnp__link-collection-wrapper.format-carousel {
    overflow: auto;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }
}
.pnp__carousel-arrow {
    width: 3rem;
    height: 3rem;
    padding: 0.75rem 0;
    margin-top: -1.5rem;
    font-size: 1rem;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 4rem);
    text-align: center;
    background: rgba($white, 0.9);
    display: none;
    opacity: 0;
    transition: box-shadow 0.2s ease-in-out;
    box-shadow: 0px 3px 5px rgba($dark-gray, 0.4);

    &:hover {
        box-shadow: 0px 2px 2px rgba($dark-gray, 0.4);
    }

    &[data-direction="right"] {
        right:30px;
    }

    &[data-direction="left"] {
        left:30px;
    }

    &.show {
        display: block;
        opacity: 1;
    }

    @include breakpoint(medium) {
        width: 5rem;
        height: 5rem;
        padding: 1.2rem 0;
        font-size: 1.7rem;
        top: calc(50% - 5rem);

        &.small {
            width: 3rem;
            height: 3rem;
            padding: 0.75rem 0;
            margin-top: -1.5rem;
            font-size: 1rem;
        }

    }
}
.pnp__link-collection {
    list-style: none;
    margin-bottom: 0;
    transition: left 0.2s linear;
    left: 0;
    padding: 5rem 0 3rem;

    @include breakpoint(medium) {
        padding: 3rem 0;
    }

    .pnp__link-collection-item {
        text-align: center;
        background: $white;
        border-radius: 2rem;
        margin-bottom: 2rem;

        &.pnp__blog-archive-article {
            text-align: left;
        }

        img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1.3rem;
        }

        .pnp__link-collection-inner {
            padding: 2rem;
        }
        .pnp__link-collection-inner.pnp__unicorn-max-height {
            align-items: center;
            margin-top: 0.5rem;
            max-height: 200px;
            .pnp__startup-meta-unicorn {
                margin: 0.7rem 0 0.9rem 0;
            }
        }

        @include breakpoint(small only) {
            .pnp__link-collection-inner {
                p { line-height: 1.4; font-size: 1.1rem; }
            }
        }

        @include breakpoint(medium) {
            .pnp__link-collection-inner {
                padding: 3rem;
            }
        }

        > a,
        > div {
            color: $body-font-color;
            display: flex;
            flex-direction: column;
            height: 100%;

            .button-container {
                padding-top: 2rem;
                margin-top: auto;
            }
        }
    }

    .button-container {
        display: none;
    }

    @include breakpoint(medium) {
        .button-container {
            display: block;
        }
    }

    &.format-carousel {
        $y-padding : 7rem;
        flex-wrap: nowrap;
        position: relative;
        padding: $y-padding 0;

        .pnp__link-collection-header {
            padding-top: $y-padding;
        }


        .pnp__link-collection-item {
            width: 40%;

            &.pnp__video-collection-item {
                width: 60%;
            }

            @include breakpoint(medium) {
                width: 25%;
            }

            @include breakpoint(large) {
                width: 16%;
            }

            @include breakpoint(medium) {
                &.pnp__video-collection-item {
                    width: 45%;
                }

                &.pnp__blog-archive-article {
                    width: 33%;
                }
            }

            @include breakpoint(large) {
                &.pnp__blog-archive-article {
                    width: 25%;
                }
            }

            max-width: 250px;

            &.pnp__blog-archive-article {
                max-width: 400px;
            }

            padding: 1rem 0.5rem 0.2rem;

            @include breakpoint(medium) {
                padding: 1.8rem 0.5rem 1.5rem;
            }

            h4 {
                font-size: 1rem;
            }

            &.pnp__unicorn-box-size {
                max-width: 280px;
                width: 280px;
                max-height: 360px;
            }

            > a, > div { padding: 0; }
        }

        .pnp__video-collection-item {
            max-width: 500px;
            padding: 0;
            position: relative;
            cursor: pointer;
            background-size: cover;

            .pnp__video-collection-inner {
                height: 240px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background: rgba(0,0,0,0.7);
                border-radius: 2rem;
                cursor: pointer;

                h4 {
                    color: #fff;
                    font-size: 1rem;
                    width: 60%;
                }

                @include breakpoint(medium) {
                    height: 300px;

                    h4 {
                        font-size: 1.6rem;
                    }
                }
            }

            .pnp__video-collection-item-icon {
                width: 100%;
                height: auto;
                text-align: center;
                color: $white;
                margin-top:0.5rem;
            }

            i {
                font-size: 2rem;
            }

            @include breakpoint(medium) {
                i {
                    font-size: 3rem;
                }
            }

            @include breakpoint(large) {
                i {
                    font-size: 3.5rem;
                }
            }
        }
    }
}
.pnp__link-collection-header {
    text-align: center;
    padding: 5rem 3rem 2rem;
    max-width: 800px;
    margin: auto;

    h2 {
        margin-bottom: 0;
    }
    @include breakpoint(medium) {
        padding: 1rem 3rem 3rem;
    }
}
.pnp__link-collection-no-top-padding .pnp__link-collection {
    padding-top: 0rem;
}
.page-section.link_collection + .page-section.button_section .pnp__button-section {
    padding-top: 0;
}
.page-section.extended_card_collection + .page-section.button_section .pnp__button-section {
    padding-top: 0;

    @include breakpoint(medium) {
        padding-bottom: 9rem;
    }
}
.pnp_centered-list-content {
    justify-content: center
}
        