.pnp__mailing-section {
    .button {
        width: 100%;
        display: block;
    }
}
.pnp__mailing-success-message,
.pnp__mailing-fail-message {
    display: none;
    text-align: center;
    padding: 3rem 2rem 0;
    font-size: 1.3rem;
}
.pnp__round-input {
    border-radius: 2rem;
    border: 0;
    padding: 1.6rem;
    box-shadow: 0px 2px 10px rgba(50,50,50,0.15);
    font-size: 1.15rem;

    &.error,
    &:invalid {
        border: 1px solid tomato;
    }
}
.pnp__mailing-input {
    &:focus {
        border: none !important;
    }
}
.pnp__mailing-signup-header {
    margin: 3rem 0;

    h1 {
        font-size: 2rem;
    }
}
.pnp__mailing-list-card {
    border-radius: 2rem;
    position: relative;
    background-color: $white;
    overflow: hidden;
    opacity: 0.6;
    height: 100%;
    cursor: pointer;

    &.selected {
        opacity: 1 !important;

        .is-checked {
            display: block;
            position: absolute;
            right: 2rem;
            bottom: 2.3rem;
            font-size: 2rem;
            color: #498dfc;
        }
    }
    
    input[type="checkbox"] {
        float:right;
    }

    h4 {
        padding: 2rem 5.5rem 2rem 2rem;
        font-size: 1.3rem;
    }

    &::before {
        height: rem-calc(160);
        content: '';
        display: block;
        background-color: var(--newsletter-color);
    }

    .is-checked {
        display: none;
    }
}
.pnp__mailing-list-button-container {
    margin-top: 3rem;
}
.pnp__mailing-body {
    background-color: #f0f0f0;
}
#pnp__mailing-list-extended-signup {
    input[type="email"] {
        margin-bottom: 3rem;
    }

    > .cell {
        margin-bottom: 2rem;
    }
}

.pnp__mailing-vertical-checkbox {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    left: -5000px;
}