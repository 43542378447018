.pnp__program-date-item-container {
    margin-bottom: 2rem;
    margin-top: 2rem;
    
    .pnp__program-date-header > div {
        background: none;
        text-transform: uppercase;
        font-size: 0.8rem;
        color: $dark-gray;
        padding-top: 0;
        padding-bottom: 0;
    }

    li .grid-x {
        width: 100%;
    }
    
    @include breakpoint(small) {
        .pnp__program-date-header {
            display: none;
        }
        .pnp__program-date-date,
        .pnp__program-date-time {
            font-size: 0.8rem;
        }
        .pnp__program-date-date {
            order: 0;
        }
        .pnp__program-date-time {
            order: 1;
            text-transform: uppercase;
        }
        .pnp__program-date-title {
            order: 3;
            font-weight: bold;
        }
        .pnp__program-date-location {
            order: 4;
        }
        .pnp__program-date-time,
        .pnp__program-date-location {
            text-align: right;
        }        
    }
    
    @include breakpoint(medium) {
        .pnp__program-date-header {
            display: flex;
        }
        
        .pnp__program-date-date,
        .pnp__program-date-time,
        .pnp__program-date-title,
        .pnp__program-date-location {
            order: 0;
            font-weight: normal;
            text-align: left;
            font-size: 1rem;
        }
    }
}