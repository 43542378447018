$interval: 1025px;
$minimunSize:639px;

.pnp__hero-block {
    min-height: 70vh;
    padding: 2rem 0;
    background-repeat: no-repeat;
    border-radius: rem-calc(24);
    position: relative;
    margin-bottom: 2rem;

    @include breakpoint(small only) {
        &.has-small-image {
            background-image: var(--var-small-background) !important;

            &:not(.pnp__video-hero) {
                background-position: bottom right !important;
                background-size: contain !important;
                background-size: var(--var-small-background-size) !important;
            }
        }
    }

    @include breakpoint(medium) {
        padding: 3rem 0;
        height: var(--var-height);

        .grid-container.flex-start {
            padding-top: 0;
        }
    }
    // irodriguez@vbote.com: Added this breakpoints to prevent bad behaviour with the background image on ebooks. 
    @include breakpoint($interval down) {
        background-position: right -180px top !important; 
    }

    @include breakpoint($minimunSize down) {        
        &.no-small-image {
            background-image: none !important;
        }
    }

    > .grid-container {
        padding:0 2rem;
    }

    .pnp__headline-tab {
        &::before {
            display: block;
            height: 8px;
            border-radius: 4px;
            background-color: var(--tab-color);
            width: 60px;
            content: '';
            margin-bottom: 1rem;
        }
    }

    @include breakpoint(medium) {
        > .grid-container {
            padding:3rem;
        }
    }

    > .grid-container,
    > .grid-container > .grid-x {
        height: 100%;
    }

    h1 {
        line-height: 1.15em;
        margin-bottom: 1rem;
        margin-top:1.5rem;
    }

    p {
        font-size: rem-calc(20);
        line-height: 1.4;
    }

    .pnp__hero-buttons {
        margin-top: 1.4rem;

        .button {
            font-size: 1rem;
        }

        .button.inline {
            margin-right: 0.75rem;
        }

        @include breakpoint(medium) {
            white-space: nowrap;
            margin-top: 3rem;

            .button.inline {
                margin-right: 0;
            }

            .button.inline + .button.inline {
                margin-left: 0.75rem;
            }

            .button.bottom-right,
            .button.bottom-left,
            .button.top-right,
            .button.top-left {
                position: absolute;
            }

            .button.bottom-left,
            .button.top-left {
                left: 2.5rem;
            }

            .button.bottom-right,
            .button.top-right {
                right: 2.5rem;
            }

            .button.bottom-right,
            .button.bottom-left {
                bottom: 1.5rem;
            }

            .button.top-right,
            .button.top-left {
                top: 1.5rem;
            }
        }
    }
}
nav:first-child + .page-section .pnp__hero-block,
.page-section:first-child .pnp__hero-block {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.pnp__thank-you-hero {
    h2::after {
        content: '';
        width: 170px;
        height: 6px;
        background: white;
        display: block;
        margin: 1rem auto;
        border-radius: 4px;
    }

    img {
        max-width: 200px;
    }
}