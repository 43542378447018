@-moz-keyframes scroll-left {
    0%   { -moz-transform: translateX(0%); }
    100% { -moz-transform: translateX(-50%); }
}
@-webkit-keyframes scroll-left {
    0%   { -webkit-transform: translateX(0%); }
    100% { -webkit-transform: translateX(-50%); }
}
@keyframes scroll-left {
    0%   { 
        -moz-transform: translateX(0%); /* Browser bug fix */
        -webkit-transform: translateX(0%); /* Browser bug fix */
        transform: translateX(0%); 		
    }
    100% { 
        -moz-transform: translateX(-50%); /* Browser bug fix */
        -webkit-transform: translateX(-50%); /* Browser bug fix */
        transform: translateX(-50%);
    }
}

.pnp__image-marquee {
    border: 3px solid $medium-gray;
    margin: 3rem 0rem;
    border-radius: 2rem;
    height: rem-calc(150);
    overflow: hidden;

    &.grayscale img {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        opacity: 0.8;
    }

    .pnp__image-marquee-container {
        height: 100%;
        white-space: nowrap;
        display: flex;
    
        &.animate {
            -moz-animation: scroll-left linear infinite;
            -webkit-animation: scroll-left linear infinite;
            animation: scroll-left linear infinite;
            -moz-animation-duration: var(--var-mobile-speed);
            -webkit-animation-duration: var(--var-mobile-speed);
            animation-duration: var(--var-mobile-speed);
            
            @include breakpoint(medium) {
                -moz-animation: scroll-left linear infinite;
                -webkit-animation: scroll-left linear infinite;
                animation: scroll-left linear infinite;       
                -moz-animation-duration: var(--var-scroll-speed);
                -webkit-animation-duration: var(--var-scroll-speed);
                animation-duration: var(--var-scroll-speed);
            }
        }
    }

    .pnp__image-marquee-inner {
        height: 100%;
        flex-wrap: nowrap;

        > .cell {
            width: 40vw;
        }
        @include breakpoint(medium) {
            > .cell {
                width: 14vw;
                padding: 0 1.4rem;
            }
        }
    }
}