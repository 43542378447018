.pnp__link-collection.format-carousel {
    .pnp__link-collection-item.pnp__video-collection-item.pnp__video-card {
        width: 55%; 

        @include breakpoint(medium) {
            width: 30%;
        }

        @include breakpoint(large) {
            width: 27%;
        }

        .pnp__video-collection-item-icon {
            position: absolute;
            height: 100%;
            width: 100%;
            top: calc(50% - 25px);
        }
            
        figure {
            position: relative;
        }
        
        figure img {
            border-top-left-radius: 2rem;
            border-top-right-radius: 2rem;
            margin-bottom: 0;
        }

        h4 {
            width: 100%;
            font-size: 1.3rem;
            color: $body-font-color;
        }

        .pnp__video-collection-inner {
            position: relative;
            background: none;
            color: #333;
            height: auto;
            padding: 1rem 1.3rem;
            text-align: left;
            display: block;

            @include breakpoint(medium) {
                padding: 1.5rem 2rem;
            }
        }
    }
}