.pnp__sub-navigation {
    background: $white;
    box-shadow: 0px 5px 10px rgba(138,138,138,0.3);
    z-index: 1011;
    position: fixed;
    top: rem-calc(80);
    left: 0;
    width: 100%;
    
    .pnp__sub-navigation-inner {
        white-space: nowrap;
        overflow: auto;
    }

    .pnp__sub-nav-header {
        display: none;
    }

    @include breakpoint(medium) {

        .pnp__sub-nav-header {
            float: left;
            display: block;
            padding: 0.73rem 0;
            font-weight: bold;
            font-size: 1.1rem;
        }

        .pnp__sub-navigation-inner {
            float:right;
            white-space: normal;
        }
    }

    ul {
        list-style: none;
        display: flex;
        margin: 0;
        padding-right: 1rem;
        
        @include breakpoint(medium) {
            padding-right: 0;

            li:last-child {
                margin-right: 0;
            }
        }

        li {     
            margin: 0 1rem;
       
            &.active {
                border-bottom: 3px solid yellow;
                font-weight: bold;
            }
            
            a { 
                display: block;
                padding: 0.8rem 0;
                color: $body-font-color;
                font-size: 0.95rem;
            }
        }
    }
}
.pnp__sub-navigation + .page-section {
    margin-top: rem-calc(53);
}