/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */

// Dependencies
@import '../_vendor/normalize-scss/sass/normalize';
@import '../_vendor/sassy-lists/stylesheets/helpers/missing-dependencies';
@import '../_vendor/sassy-lists/stylesheets/helpers/true';
@import '../_vendor/sassy-lists/stylesheets/functions/contain';
@import '../_vendor/sassy-lists/stylesheets/functions/purge';
@import '../_vendor/sassy-lists/stylesheets/functions/remove';
@import '../_vendor/sassy-lists/stylesheets/functions/replace';
@import '../_vendor/sassy-lists/stylesheets/functions/to-list';

// Settings
// import your own `settings` here or
// import and modify the default settings through
@import 'settings';

// Sass utilities
@import 'util/util';

// Global variables and styles
@import 'global';

// Components
@import 'grid/grid';
@import 'xy-grid/xy-grid';
@import 'typography/typography';
@import 'forms/forms';
@import 'components/visibility';
@import 'components/float';
@import 'components/button';
@import 'components/button-group';
@import 'components/accordion-menu';
@import 'components/accordion';
@import 'components/badge';
@import 'components/breadcrumbs';
@import 'components/callout';
@import 'components/card';
@import 'components/close-button';
@import 'components/drilldown';
@import 'components/dropdown-menu';
@import 'components/dropdown';
@import 'components/flex';
@import 'components/responsive-embed';
@import 'components/label';
@import 'components/media-object';
@import 'components/menu';
@import 'components/menu-icon';
@import 'components/off-canvas';
@import 'components/orbit';
@import 'components/pagination';
@import 'components/progress-bar';
@import 'components/reveal';
@import 'components/slider';
@import 'components/sticky';
@import 'components/switch';
@import 'components/table';
@import 'components/tabs';
@import 'components/title-bar';
@import 'components/top-bar';
@import 'components/thumbnail';
@import 'components/tooltip';
@import 'components/hubspot-mailing';
@import 'prototype/prototype';

@include foundation-flex-classes;

$global-flexbox: true !global;
@include foundation-xy-grid-classes;
@include foundation-global-styles;
@include foundation-typography;
@include foundation-forms;
@include foundation-button;
@include foundation-callout;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-label;

@import 'components/icons';
@import 'components/core';
@import 'components/header';
@import 'components/footer';
@import 'components/sub-navigation';
@import 'components/button-block';
@import 'components/image-marquee';
@import 'components/link-collection';
@import 'components/stat-collection';
@import 'components/hero';
@import 'components/masonry';
@import 'components/video-hero';
@import 'components/half-content-image';
@import 'components/image-grid';
@import 'components/filterable-block';
@import 'components/text-hero';
@import 'components/startup-index';
@import 'components/case-study-index';
@import 'components/dark-theme';
@import 'components/program-date-index';
@import 'components/blog';
@import 'components/due-diligence';
@import 'components/heading-section';
@import 'components/team-member';
@import 'components/useful-links';
@import 'components/contact';
@import 'components/map';
@import 'components/event';
@import 'components/calendar';
@import 'components/auto-play-video';
@import 'components/company-collection';
@import 'components/big-explainer';
@import 'components/overlay';
@import 'components/extended-card';
@import 'components/checklist';
@import 'components/video-collection';
@import 'components/apple-hero';
@import 'components/team-page';
@import 'components/mailing-list';