#pnp__calendar {
    padding-top: 3rem;
}
td.fc-day-top,th.fc-day-header {
    width: 14.28%;
}

td.fc-day-top {
    text-align: right;
    padding: 5px 10px;
}

.fc-month-view,.fc-basicWeek-view {
    background-color: $white;
}
td.fc-head-container.fc-widget-header {
    border-bottom: 0 hidden transparent;
}
.fc-view > table {
    border-radius: 10px;
    border-collapse: collapse;
}
.fc-day-grid-container {
    height: auto !important;
    overflow-y: auto !important;
}
.fc-row.fc-week {
    height: auto !important;
    
    .fc-content-skeleton {
        padding-top: 0.5rem;
    }
}
.fc-content {
    margin: 3px 10px 0px;
    font-size: 0.8rem;
    padding: 7.5px 10px 7.5px 5px;
    border-left: 3px solid;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    line-height: 1.4;
}
.fc-scroller {
    height: auto !important;
}
.fc-event-container {
    vertical-align: top;
    padding: 0;
}
.fc-header-toolbar {
    margin-bottom: 2rem;
    display: flex;
    flex-wrap: wrap;

    > div {
        width: 100%;
    }


    .fc-center {
        text-align: center;
    }

    .fc-center {
        order: 0;
    }

    .fc-left {
        order: 1;
        text-align: center;

        @include breakpoint(medium) {
            order: 0;
        }
    }

    .fc-right {
        order: 2;
    }

    .fc-center,.fc-left,.fc-right {
        margin-bottom: 0.5rem;
    }
    
    .fc-clear {
        display: none;
    }

    @include breakpoint(medium) {
        > div {
            width: 33.33333333%;
        }

        .fc-right {
            order:2;
        }

        .fc-left { 
            text-align: left;
        }
    }
  

    h2 {
        text-align: center;
        font-size: 1.3rem;
        font-weight: bold;
        margin-bottom: 0;
        display: inline-block;
        padding: 0 10px;
    }
}

.fc-left {
    @include breakpoint(small only) {
        display: none;
    }

    button {
        padding: 10px 15px;
        border: 1px solid #dadada;
        text-transform: uppercase;
        font-size: 0.7rem;
        color: #777;
        border-left-width: 0px;

        &:first-child {
            border-left-width: 1px;
        }
    }
    
    button:last-child {
        border-top-right-radius: 17px;
        border-bottom-right-radius: 17px;
    }
    
    .fc-state-active {
        background: white;
    }
    
    button:first-child {
        border-top-left-radius: 17px;
        border-bottom-left-radius: 17px;
    }
}
.fc-center {
    h2 {
        margin-bottom: 1rem;
    }

    button {
        margin: 0 0.5rem;
        color: $body-font-color;
    }
}
.grid-container > #pnp__legend {
    display: none;
}

.fc-view-container {
    border: 1px solid #dadada;
    border-radius: 10px;
    overflow: hidden;

    > .fc-view {
        margin: -1px;
    }
}
.fc-row.fc-widget-header table {
    width: 100%;
}

th.fc-day-header {
    text-transform: uppercase;
    font-size: 0.9rem;
    padding: 1rem;
}

thead.fc-head {
    border-collapse: collapse;
}

tbody.fc-body {
    border-top: transparent;
}
.fc-content-skeleton, .fa-bg {
    min-height: 130px;
}
.fc-content-skeleton > table {
    border-collapse:  collapse;
}

.fc table {
    border-collapse: collapse;
}

.fc-unthemed tbody {
    border-color: #ddd;
}

.fc td {
    border: 1px solid #dadada;
    vertical-align: top;
}

.fc-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.fc .fc-row .fc-content-skeleton table, 
.fc .fc-row .fc-content-skeleton td, 
.fc .fc-row .fc-helper-skeleton td {
    background: 0 0;
    border-color: transparent;
}

.fc-content-skeleton {  
    position: relative;
    z-index: 4;

    tr:last-child td {
        padding-bottom: 5px;
    }
}
.fc-row {
    position: relative;
}

.fc table {
    width: 100%;
    height: 100%;
    border-spacing: 0;
    table-layout: fixed;
    border-collapse: collapse;
    box-sizing: border-box;
}

.fc-row table {
    border-left: 0 hidden transparent;
    border-right: 0 hidden transparent;
    border-bottom: 0 hidden transparent;
}
.pnp__blue-calendar .fc-content {
    background-color: #DCE7FF;
    color: #457AFD;
}
.pnp__red-calendar .fc-content {
    background-color: #F7C5D1;
    color: #EC3657;
}
.pnp__yellow-calendar .fc-content {
    background-color: #FCF0DB;
    color: #F2AD55;
}
#pnp__legend {
    ul {
        margin: 0;
        text-align: center;
    }

    @include breakpoint(medium) {
        text-align: center;
    }
}
.pnp__calendar-legend {
    border-radius: 2rem;
    padding: 0.5rem 1rem;
    border-width: 1px;
    border-style: solid;
    font-size: 0.75rem;
    font-weight: bold;
    display: inline-block;
    margin: 0 0.25rem;

    @include breakpoint(medium) {
        margin:  0 0 0 0.5rem;
    }
}
.pnp__blue-calendar-legend {
    background-color: #DCE7FF;
    color: #457AFD;
}
.pnp__red-calendar-legend {
    background-color: #F7C5D1;
    color: #EC3657;
}
.pnp__yellow-calendar-legend {
    background-color: #FCF0DB;
    color: #F2AD55;
}
.fc-list-table {
    table-layout: auto !important;
    background-color: $white;

    .fc-widget-header {
        padding: 1rem;
        font-weight: bold;
        
        .fc-list-heading-main {
            display: inline-block;
        }

        .fc-list-heading-alt {
            float: right;
        }
    }

    .fc-event-dot {
        width:10px;
        height: 10px;
        border-radius: 5px;
        display: inline-block;
    
    }

    .fc-list-item {
        border-style: solid;
        border-width: 1px 0 0;
    }

    .fc-list-item td {
        padding: 0.6rem 1rem;
        border: 0;
        vertical-align: middle;

        &.fc-list-item-marker {
            padding: 0.6rem 0;
            text-align: center;
        }
    }

    .fc-list-item-marker,
    .fc-list-item-name {
        width: 1px;
        white-space: nowrap;
    }

    

    .pnp__blue-calendar .fc-event-dot {
        background-color: #457AFD;        
    }

    .pnp__red-calendar .fc-event-dot {
        background-color: #EC3657;        
    }

    .pnp__yellow-calendar .fc-event-dot {
        background-color: #F2AD55;        
    }
}