.pnp__masonry {
    list-style: none;
    margin-bottom: 0;
    
    .pnp__masonry-item-container {
        margin-bottom: 2rem;
        height: 450px;

        @include breakpoint(medium) {
            height: 600px;
        }
    }

    .pnp__masonry-item {
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: contain;
        border-radius: 2rem;
        width: 100%;
        height: 100%;
        display: block;
        text-decoration: none;

        @include breakpoint(small only) {
            &.has-small-image {
                background-image: var(--var-small-background) !important;
            }
        }
        
        .pnp__masonry-item-inner {
            width: 65%;
            margin: auto;
            padding: 4rem 0;
        }
    }
    
    .label {
        font-size: 1rem;
        border-radius: 2em;
        text-transform: uppercase;
        padding: 0.5rem 1.2rem;
        margin-bottom: 1rem;

        &:empty {
            visibility: hidden;
            height: 2rem;
        }
    }
    
    h3 {
        font-size: 2.1rem;
    }
    
    p {
        font-size: 1.15rem;
        line-height: 1.4;
    }
}