.pnp__contact-form-choice {
    max-width: rem-calc(1000);
    margin: auto;
    list-style: none;
    background: #f4f4f4;
    border-radius: 2rem;
    max-width: rem-calc(1000) !important;
    padding: 4rem 3rem !important;
    margin: auto auto 2rem !important;


    li {
        text-align: center;
        margin-bottom: 1.5rem;

        @include breakpoint(medium) {
            margin-bottom: 0;
        }

        &:hover .pnp__contact-form-choice-inner,
        &.active .pnp__contact-form-choice-inner {
            background: #14BF8D;
            color: $white;
        }

        .pnp__contact-form-choice-inner {
            background: $white;
            height: 100%;
            display: flex;
            flex-direction: row;
            padding: 4rem 2rem;
            border-radius: 2rem;
            cursor: pointer;
            -webkit-user-select: none;


            > span {
                margin: auto;
                font-size: 1.3rem;
            }
        }
    }
}

ul.inputs-list.multi-container {
    display: flex;
    flex-wrap: wrap;

    li {
        list-style: none;
        width: 100%;

        span {
            font-size: 0.9rem;
            vertical-align: middle;
            line-height: 1;
        }

        @include breakpoint(medium) {
            width: 33.3333%;
        }
    }
}

.pnp__contact-form-header {
    h2 {
        margin-bottom: 3rem;
    }
}

.pnp__contact-form {
    padding: 0 1rem;
    margin-bottom: rem-calc(100);

    form {
        margin: 3rem 0;
        padding-bottom: 0rem;
    }

    input {
        margin-bottom: 0.5rem;
    }

    label {
      margin-bottom: 0.5rem;

    }

    fieldset {
        background: #f4f4f4;
        border-radius: 2rem;
        max-width: rem-calc(1000) !important;
        padding: 1rem 1.5rem !important;
        margin: auto auto 2rem !important;

        @include breakpoint(medium) {
            padding: 2rem 3rem !important;
        }
    }

    fieldset:last-of-type {
        background: #fff;
        padding: 0rem 0rem !important;
        margin: auto auto 2rem !important;
    }

    .hs-input[type="checkbox"] {
        margin-bottom: 0;
        vertical-align: middle;
        font-size: 2.5rem;
    }

    fieldset:last-of-type .hs-richtext {
        padding: 0;
        font-size: 1rem;
    }

    .hs-richtext {
        padding: 0 1rem .5rem 1rem;
        font-size: 1rem;
    }

    .field.hs-form-field {
        position: relative;
        margin-bottom: 1rem;
    }

    fieldset .hs-form-field .input .hs-input:not([type="checkbox"]) {
        border-radius: 2rem;
        border: 0;
        padding: 1.5rem;
        box-shadow: 0px 2px 10px rgba(50,50,50,0.15);
        font-size: .9rem;
        width: 100%;
    }

    fieldset .hs-form-field.field .input select.hs-input {
        padding: 0 2rem 0 1.5rem;
        height: 50px;
    }

    form fieldset.form-columns-3 {
      display: contents
    }

    .hs_error_rollup {
        max-width: 1000px;
        margin: auto;
        font-size: 2rem;
        padding: 0 1rem;
    }

    ul.hs-error-msgs.inputs-list {
        color: #f2545b;
        margin-left: 1.25rem;
        list-style-type: none;

        li {
            font-size: .8rem;
        }
    }

    textarea.hs-input {
        min-height: 300px;
        resize: none;
    }

    .hs-richtext h2 {
        margin-bottom: 2rem;
        font-size: 1.9rem;
    }

    .hs-richtext p {
        margin-bottom: 0;
        text-align: left !important;
    }

    .actions {
        text-align: center;
    }

    .hs-button {
        background: #14bf8d;
        font-size: 1rem;
        padding: 0.6em 1em;
        border-radius: 0.6rem;
        border: 0;
        color: $white;
    }

    .hs-form-required {
    }

    .legal-consent-container .hs-form-booleancheckbox-display{
        display: flex;
        align-items: center;
    }

    .legal-consent-container .hs-form-booleancheckbox-display>span {
        margin-left: 0;
    }

    ul {
        margin-bottom: 0 !important;
    }
}

.pnp__contact-form-hero {
    .hbspt-form {
        margin: 3rem 0;
        padding-bottom: 0rem;
        color: #444;
    }

    fieldset {
        background: #f4f4f4;
        border-radius: 1.5rem;
        max-width: rem-calc(1000) !important;
        padding: 0.5rem !important;
        margin: auto auto 1rem !important;
    }

    div.hs-richtext {
        margin: 1rem;
        font-size: 1.2rem;
        font-weight: bold;
    }

    div.hs-richtext div {
        text-align: left !important;
    }

    .hs-input[type="checkbox"] {
        margin-bottom: 0;
        vertical-align: middle;
        font-size: 2.5rem;
    }

    .field.hs-form-field {
        padding: 0.4rem 1rem;
        position: relative;
        width: 100% !important;
    }

    fieldset .hs-form-field .input .hs-input:not([type="checkbox"]) {
        border-radius: 0.5rem;
        border: 0;
        padding: 0.5rem;
        box-shadow: 0px 2px 10px rgba(50,50,50,0.15);
        font-size: 1rem;
        width: 100%;
    }

    fieldset .hs-form-field.field .input select.hs-input {
        padding: 0 1.5rem;
        height: 50px;
    }

    .actions {
        text-align: left;
    }

    .hs-button {
        background: #498DFC;
        font-size: 1rem;
        padding: 1em 2em;
        border-radius: 0.6rem;
        border: 0;
        color: $white;
    }

    .hs-form-required {
    }
}