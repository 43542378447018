.pnp__big-explainer {
    margin-bottom: 2rem;
    padding-top:3rem;
    padding-bottom: 3rem;

    &.has-buttons {
        .grid-x > div:first-child {
            margin-top: auto;
        }   
    }

    .grid-x {
        min-height: 40vh;
        max-height: 50vh;

        @include breakpoint(medium) {
            min-height: 50vh;            
        }
    }

    .pnp__button-container {
        align-self: flex-start;
        margin-top: 4rem;
        margin-bottom: -1rem;

        .button + .button {
            margin-left: 0.5rem;
        }
    }

    p {
        margin: 0;
        font-size: 2.6rem;
        font-weight: 600;
        line-height: 1.2;
        letter-spacing: -1px;

        &.pnp__big-explainer-medium {
            font-size: 1.8rem;
        }

        &.pnp__big-explainer-small {
            font-size: 1.4rem;
        }

        @include breakpoint(small only) {
            line-height: 1.4;
            font-size: 1.8rem;

            &.pnp__big-explainer-medium {
                font-size: 1.4rem;
            }
        }

        &::before {
            content : '';
            max-width: 120px;
            height: 8px;
            border-radius: 4px;
            margin: auto;
            margin-bottom: 2rem;
            background-color: var(--bar-color);
            display: block;
        }
    }
}