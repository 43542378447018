.pnp__video-hero {
    cursor: pointer;
    padding: 1.9rem 0;
    background-size: cover;

    @include breakpoint(small only) {
        background-position: center;
        background-repeat: no-repeat;
        background-image: var(--var-small-background) !important;
    }

    .button {
        border-width: 2px;
    }

    .grid-container {
        padding: 0 2rem;
    }

    h3 {
        text-shadow: 0px 1px 8px rgba($black,0.6);

        @include breakpoint(small only) {
            font-size: 1.4rem;
            margin-bottom: 1rem;
        }
    }

    &.middle {
        h1 {
            font-size: 2.4rem;
            margin-bottom: 1.2em;
            text-shadow: 0px 0px 10px rgba(0,0,0,0.5);
        }

        .grid-x {
            min-height: calc(70vh - 3.8rem);
        }

        @include breakpoint(medium) {
            h1 {
                font-size: 3.8rem;
            }

            .button {
                font-size: 1.3rem;
            }
        }
    }
}
.pnp__overlay-video {
    .pnp__overlay-video-inner {
        max-width: 60vmax;
        margin: 12vmax auto;
        border-radius: 2rem;
        overflow: hidden;


        @include breakpoint(small only) {
            margin-top: 40vh;
        }
    }

    .pnp__video-wrapper {
        padding-bottom: 56.5%;
        position: relative;

        iframe {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            border: 0;
            height: 100%;
        }
    }
}
