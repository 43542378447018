.pnp__due-diligence-page {
    padding-top: 9rem;

    @include breakpoint(medium only) {
        padding-top: calc(9rem + 40px);
    }
}
.pnp__press-page,
.pnp__due-diligence-page {
}
.pnp__video-page {
    padding-top: rem-calc(123);
}
.responsive-object {
    position: relative;

    iframe {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        overflow: hidden;
    }
}
.pnp__due-diligence-navigation {
    background: $white;
    overflow: auto;
    position: fixed;
    left: 0;
    right:0;
    top: 5rem;
    z-index: 1010;
    box-shadow: 0px 5px 10px rgba(138,138,138,0.2);

    @include breakpoint(medium only) {
        top: rem-calc(120);
    }

    .pnp__due-diligence-navigation-list {
        list-style: none;
        margin: 0;
        white-space: nowrap;
    }
}
.pnp__due-diligence-navigation-list li {
    display: inline-block;
    padding: 0.5rem 0.25rem;
}
.pnp__due-diligence-tag-item,
.pnp__due-diligence-navigation-list li a {
    display: inline-block;
    padding: 0.2rem 0.9rem;
    font-size: 0.8rem;
    border-radius: 0.8rem;
    text-transform: uppercase;
    font-weight: bold;
}
.pnp__due-diligence-tag-item {
    margin-top: 0.3rem;
}
.pnp__due-diligence-related-posts {
    padding: 0;
    margin: rem-calc(60) 0;
}
.pnp__due-diligence-archive {

    &.pnp__due-diligence-archive--tag > header {
        margin-top: 3rem;
    }

    > header {
        display: flex;
        margin-top: 1rem;
        margin-bottom: 2.5rem;

        @include breakpoint(medium) {
            margin-bottom: 2rem;
        }

        h1 {
            line-height: 1;
        }
    }

    // irodriguez: Removed this media query to make the due diligence list behave like press instead of blog. 
}
.pnp__due-diligence-page-tags {
    list-style: none;
    margin: 0;
    display: flex;
    margin-bottom: 0.9rem;
    text-transform: uppercase;


    &.press {
        li {
            padding: 0.3rem 0.7rem;
            border-radius: 0.5rem;
            color: white;
        }
    }

    li {
        margin-right:1rem;

        a {
            padding: 0.3rem 0.7rem;
            border-radius: 0.5rem;
        }
    }
}
.pnp__due-diligence--list {
    list-style: none;
    margin-left: 0;
}
.pnp__press-program {
    margin-right: 1rem;

    a {
        padding: 0.3rem 0.7rem;
        border-radius: 0.5rem;
        font-size: 0.9rem;
        font-weight: bold;
        display: inline-block;
        color: white;
        text-transform: uppercase;
    }
}
.pnp__press-filter-cloud {
    margin-bottom: 2rem;
}
.pnp__due-diligence-archive-article {
    margin-bottom: 2rem;
    width: 100%;

    a {
        color: $body-font-color;
    }

    .pnp__due-diligence-article-tags {
        padding: 0 1rem 1rem;

        @include breakpoint(medium) {
            padding: 0 2rem 2.5rem;
        }

        margin: 0;
        margin-top: auto;

        li {
            margin-right: 0.5rem;
        }
    }

    .pnp__due-diligence-archive-excerpt {
        display: none;
    }

    a {
        background: #fff;
        border-radius: 2rem;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    width: 50%;

    @media screen and (min-width : rem-calc(900)) {
        width: 33.33333%;
        min-height: rem-calc(500);
    }
}

.pnp__due-diligence-archive-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    header {
        padding: 1rem;
        margin-bottom: 0;

        h1 {
            font-size: 1.1rem;
            line-height: 1.3;
            margin-bottom: 0;
        }

        @include breakpoint(medium) {
            padding: 1.5rem 2rem 0;

            h1 {
                font-size: 1.7rem;
            }
        }
    }
}
.pnp__due-diligence-archive-image img {
    border-top-left-radius: 2rem;
    border-top-right-radius:  2rem;
    width: 100%;
}
.pnp__due-diligence-content-container {
    padding-top: 1rem;
    padding-bottom: 5rem;

    header {
        margin-bottom: 2rem;
    }

    .pnp__due-diligence-page-body {
        border-radius: 1rem;

        @include breakpoint(large) {
            border-radius: 2rem;
        }

        ol, ul {
            margin-left: 3.5rem;
        }

        .responsive-object {
            margin: 2rem -8.333333%;
        }

        img.full-width {
            width: 116%;
            max-width: none;
            margin: 2rem -8%;
        }

        background: #fff;
        padding-bottom: rem-calc(80);
        font-size: 1.3rem;

        .pnp__due-diligence-page-header-image {
            margin-bottom: 2rem;

            img {
                border-top-left-radius: 1rem;
                border-top-right-radius: 1rem;
            }

             @include -zf-each-breakpoint {
                $margin: rem-calc(-zf-get-bp-val($grid-column-gutter, $-zf-size)) / 2 * -1;

                margin-right: $margin;
                margin-left: $margin;
            }

            @include breakpoint(medium) {
                margin-bottom: 4rem;
            }

            img {
                @include breakpoint(large) {
                    border-top-left-radius: 2rem;
                    border-top-right-radius: 2rem;
                }
                width: 100%;
            }
        }
    }
}

.pnp__due-diligence-collections {
    padding: 0rem 0 1rem;
    display: flex;
    margin: 0 -0.625rem;
    white-space: nowrap;

    &.home {
        .pnp__due-diligence-collection {
            margin-left:0.625rem;
            margin-right:0.625rem;

            .pnp__due-diligence-collection-inner {
                height: rem-calc(375);
            }
        }
    }
}
.pnp__due-diligence-collection-wrapper {
    overflow: auto;
    overflow: moz-scrollbars-none;

    .pnp__carousel-arrow {
        z-index: 1010;
        opacity: 0;
        transition: all 0.3s ease-in;
        margin-top: 0;

        &[data-direction="left"] {
            left:10px;
        }

        &[data-direction="right"] {
            right:10px;
        }

        &.show {
            opacity: 1;
            display: block;
        }
    }

    &::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none;
}
.pnp__due-diligence-collection {
    position: relative;
    display: block;
    width: 75vw;
    margin-bottom: 2.5rem;
    white-space: normal;
    flex: 0 0 auto;

    @include breakpoint(medium) {
        width:33vw;
    }

    @include breakpoint(large) {
        width:22.5vw;
    }

    &:hover {
        &::after {
            bottom: -12%;
        }

        &::before {
            bottom: -5%;
        }
    }
}
.pnp__due-diligence-collection-inner {
    display: flex;
    color: #fff;
    height: rem-calc(200);
    flex-direction: column;
    border-radius: 2rem;
    padding: 3rem 2rem;
    position: relative;
    z-index: 10;
    display: flex;

    @include breakpoint(small only) {
        h3 {
            font-size: 1.6rem;
        }
    }

    @include breakpoint(medium) {
        height: rem-calc(375);
    }

    .pnp__due-diligence-collection-description {
        margin-top: auto;
    }
}
.pnp__due-diligence-collection::after,
.pnp__due-diligence-collection::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 1;
    border-radius: 2rem;
    transform: scale(0.95);
    bottom: -6%;
    opacity: 0.2;
    transition: bottom 0.1s linear;
    background-color: var(--bg-col);
}
.pnp__due-diligence-collection::after {
    bottom: -14%;
    transform: scale(0.85);
    z-index: 0;
}

.pnp__due-diligence-video-container {
    .pnp__due-diligence-video-background-container {
        background: #000;
    }

    .pnp__link-collection-header {
        padding: 1rem 3rem 3rem;
    }

    .pnp__link-collection-container {
        max-width: 990px;
    }

    .pnp__link-collection.format-carousel {
        padding: 0;
        margin-bottom: 2rem;
    }

    .pnp__due-diligence-video {
        width: 100%;
        height: calc((9/16)*100vw);
        max-height: calc(100vh - 200px);
        position: relative;

        iframe {
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            border: 0;
        }
    }

    article {
        padding-top: 2rem;
        padding-bottom: 2rem;

        .rich-text {
            font-size: 1.2rem;
        }

        h1 {
            font-size: 1.6rem;
            line-height: 1.2;
        }

        p {
            line-height: 1.5;
        }

        @include breakpoint(medium) {
            h1 {
                font-size: 2.2rem;
            }
        }

        h2 {
            font-size: 1.8rem;
        }

        h3 {
            font-size: 1.5rem;
        }

    }
}
.pnp__due-diligence-content-container {
    h1 {
        font-size: 3rem;
        line-height: 1.1;
    }

    @include breakpoint(small only) {
        h1 {
            font-size: 2.2rem;
        }

        h2 {
            font-size: 1.8rem;
        }

        h3 {
            font-size: 1.5rem;
        }
    }

    img {
        border-radius: 1rem;
    }

    .richtext-image {
        margin-bottom: 2rem;
        max-width: 350px;
    }

    .richtext-image.left,
    img.alignleft {
        float: left;
        margin-right: 2rem;
        margin-left: -8.333333%;
    }

    .richtext-image.right,
    img.alignright {
        float: right;
        margin-left: 2rem;
        margin-right: -8.333333%;
    }

    img.aligncenter,
    img.alignnone {
        width: 116%;
        max-width: none;
        margin-left: -8%;
    }

    .video-container {
        width: 116%;
        margin-left: -8%;
        padding-top: 56.5%;
        position: relative;
        margin-top: 2rem;
        margin-bottom: 2rem;
        border-radius: 2rem;
        overflow: hidden;

        p {
            margin: 0;
        }

        iframe {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }

    blockquote {
        margin-bottom: 1.5rem;
        border-left: 0;
        font-weight: bold;
        line-height: 1.35;
        font-size: 1.5rem;

        @include breakpoint(medium) {
            width: 52%;
            float: right;
            margin-right: -14%;
            margin-left: 2rem;
            font-size: 2rem;
        }

        &::before {
            content: '';
            margin-bottom: 2rem;
            height: 10px;
            width: 100%;
            display: block;
            border-radius: 5px;
            background: linear-gradient(90deg, #001257 0%, #00c2de 100%);
        }
    }
}
.pnp__due-diligence-filter-label {
    background-color: #eaeaea;
    color: #555;

    &:hover {
        color: #555;
    }
}
.pnp__due-diligence-sep {
    &::after {
        content: '';
        display: inline-block;
        background: #e6e6e6;
        margin: 0 0.9rem 0 1.3rem;
        height: 22px;
        width: 1px;
        vertical-align: middle;
    }
}
.pnp__video-navigation {
    .pnp__due-diligence-navigation {
        margin-bottom: 0;
    }
}
.pnp__due-diligence-post-collection-footer {
    padding: 3rem;
    border-radius: 2rem;
    margin-top: rem-calc(80);
    background-color: yellow;

    .pnp__due-diligence-collection {
        width: 100%;
        margin-bottom: 4rem;

        .pnp__due-diligence-collection-inner {
            height: rem-calc(300);

            @include breakpoint(small only) {
                h3 {
                    font-size: 1.5rem;
                }
            }
        }

        @include breakpoint(medium) {
            margin-bottom: 2rem;

            .pnp__due-diligence-collection-inner {
                height: rem-calc(450);
            }
        }
    }

    .pnp__due-diligence-post-collection-description {
        display: flex;
        flex-direction: column;
        color: #20474B;

        .button {
            border-color: #20474B;
            color: #20474B;

            &:hover {
                background: #20474B !important;
                color: white;
            }
        }

        h2 {
            margin-bottom: 1.4rem;
        }

        @include breakpoint(medium) {
            p {
                font-size: 1.3rem;
                margin-bottom: 1.4rem;
            }
        }

        .pnp__due-diligence-post-collection-buttons {
            margin-top: auto;
        }
    }
}
.pnp__due-diligence-attribute {
    margin-left: 2px;
}