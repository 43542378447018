.pnp__extended-card {
    .pnp__link-collection-inner {
        padding: 1.5rem 1.5rem 3rem !important;
    }

    figure {
        margin: -1.5rem -1.5rem 1rem;

        img {
            border-top-left-radius: 2rem;
            border-top-right-radius: 2rem;
        }
    }

    &.pnp__link-collection-item {
        text-align: left;
    }

    .pnp__card-description {
        margin-bottom: 0;
        font-size: 1.1rem;
    }

    hr {
        border-bottom: 2px solid $light-gray;
        width: 100%;
        margin: 2.3rem 0;
    }

    h4, h5 {
        color: #333;
    }
}