.pnp__overlay {
    background:transparentize(#121822, 0.4); 
    height:  100%;
    left: 0;
    overflow: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;

    .pnp__overlay-inner {
        background: #ffffff;
        border-radius: 2rem;
        margin: 15vmax auto;
        max-width: 550px;
        min-height: 370px;
        padding: 1.3rem 2rem;
        min-height: 370px;

        @include breakpoint(small only medium only) {
            margin: 21vmax auto;
            max-width: 350px;
        }
    }
}