.pnp__auto-play-video-block {
    overflow: hidden;
    border-radius: 0;
    margin-bottom: 0;

    .pnp__auto-play-video-overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        z-index: 1;

        @include breakpoint(medium) {
            background-image: none !important;
            background-color: var(--var-background-color);
        }

        .grid-x, .grid-container {
            height: 100%;
        }
    }

    iframe {
        display: none;
    }

    @include breakpoint(medium) { 
        iframe {
            display: block;
            position: absolute;
            left: calc((100vw - 100%) / -2);
            right: 0;
            width: 100vw;
            height: 100vw;
            border: 0;
            top: -25vw;
            transform: scale(1.3);
        }
    }

    h1 {
        font-size: 2rem;
        text-transform: uppercase;
        display: inline-block;

        &::after {
            background-color: var(--var-underline-color);
            height: 6px;
            display: block;
            margin: 1rem 0;
            width:100%;
            border-radius: 6px;
            content: '';
        }
    }

    p {
        font-size:3.2rem;
        font-weight: bold;
        line-height: 1;
        letter-spacing: -1px;
    }

    @include breakpoint(small only) {
        min-height: 60vh;
        
        h1, p {
            font-size: 1.4rem;
        }
    }
}