.pnp__event-container,
.pnp__headline-event-container { 
    margin-top: 1rem;
    list-style: none;
}
.pnp__event-list-container {
    margin-bottom: 2rem;

    > li {
        margin-bottom: 2rem;
    }

    time {
        display: block;
    }
    
    .pnp__headline-event-item-location {
        margin-top: auto;
    }
}
.pnp__headline-event-container {    
    a {
        color: $body-font-color;
    }
    
    > li {
        height: auto;
        min-height: auto;
        
        > article {
            height: 100%;
            
            time {
                margin-bottom: 2rem;
                display: block;
            }
        }
    }

    .pnp__headline-event-content {
        padding: 1.4rem 1.4rem 3rem;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        
        h2 {
            font-size: 1.4rem;
            font-weight: bold;
            margin-bottom: 0.2rem;
        }
    }
}

.pnp__event-container {
    article { 
        height: 100%;
    }

    article > a {
        background-color: $white;
        border-width: 2px;
        border-style: solid;
        padding: 2rem 1.3rem;
        color: $body-font-color;
        border-radius: 2rem;
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        
        h4 {
            font-weight: bold;
        }
        
        time {
            margin-bottom: 1rem;
        }
    }
}
.pnp__headline-event-image {
    border-radius: 2rem;
    overflow: hidden;

    @include breakpoint(small only)  {
        margin-bottom: 2rem;
    }

    figure {
        overflow: hidden;
        border-radius: 2rem;
    }

    @include breakpoint(medium) {
        figure {
            height: 100%;
            width: 100%;
            position: relative;
        }

        img {
            position: absolute;
            left: 0;
            width: 100%;
            height: auto;
            top: -15%;
        }
    }
}
.pnp__headline-virtual-event-image {
    border-radius: 2rem;
    overflow: hidden;

    @include breakpoint(small only)  {
        margin-bottom: 2rem;
    }

    figure {
        overflow: hidden;
        border-radius: 2rem;
    }

    @include breakpoint(medium) {
        figure {
            height: 100%;
            width: 100%;
            position: relative;
        }

        img {
            left: 0;
            width: 100%;
            height: auto;
            top: -15%;
        }
    }
}

.pnp__event-image-max-size {
    align-content: center;
    align-items: center;
    display: flex;
    
    img {
        margin-left: auto;
        margin-right: auto;
        max-height: 578px;
        max-width: 1104px;
        border-radius: 2rem;
    }
}

.pnp__hideLowResolution {
    @include breakpoint(small down) {
        display: none;
    }
}

.pnp__showLowResolution {
    @include breakpoint(small up) {
        display: none;
    }
    @include breakpoint(small down) {
        display: block;
    }
}

.pnp__headline-event-title {
    margin-bottom: 3rem;
    margin-top: 3rem;

    h1 {
        font-weight: bold;
    }

    p {
        font-size: 1.3rem;
        line-height: 1.3;
    }
}
.pnp__event-header {
    margin-top: 2rem;
    
    h1 {
        margin-bottom: 3rem;
    }
}

.pnp__headline-event-dates {
    margin-bottom: 2rem;
    background-color: #fff;
    border-radius: 2rem;
    padding: 1.3rem 1.5rem;
} 

.pnp__headline-virtual-event-dates {
    padding: 1.3rem 0;
}

.pnp__headline-event-location {
    background: white;
    border-radius: 2rem;
    overflow: hidden;
}

.pnp__headline-event-address {
    padding: 1.5rem;
    font-weight: bold;
}

.pnp__headline-event-map {
    height: 250px;
}
.pnp__headline-event-map iframe {
    width: 100%;
    border: 0;
    height: 250px;
    display: block;
}

.pnp__headline-event-body {
    background: #fff;
    padding: 4rem 0;
    margin-top: 3rem;
}
.pnp__headline-virtual-event-body {
    background: #fff;
    padding: 2rem 0 4rem 0;
    margin-top: 1rem;
}
.pnp__headline-event-separator{
    border-bottom: 1px solid #e6e6e6;
}
.pnp__headline-event-description {
    p {
        font-size: 1.3rem;
    }
}
.pnp__headline-event-register {
    margin-top: 3rem;
}
.pnp__headline-event-header-date {
    font-size: 1.2rem;
    font-weight: bold;
}
.pnp__headline-event-agenda {
    ul {
        margin: 0;
    }

    li {
        margin-bottom: 1rem;
        padding: 1rem;
        background: #f0f0f0;
        border-radius: 1rem;
    }
}
.pnp__headline-event-speakers {
    > h4 {
        margin: 1.5rem 0;
    }

    ul {
        list-style: none;

        h4 {
            font-size: 1.2rem;
        }

        img {
            width: 100%;
        }
    }

    .pnp__headline-event-speaker {
        background: #f0f0f0;
        border-radius: 1rem;
        overflow: hidden;
        margin-bottom: 2rem;
    }

    .pnp__headline-event-speaker-details {
        padding: 1.5rem 1rem;
    }
}
.pnp__headline-event-agenda {
    margin-top: 1rem;

    h4 {
        margin-bottom: 1rem;
    }
}

/* Full Headline Event styles*/

.pnp__fullheadline-event {
  .pnp__blog-page {
    padding-top: 5rem !important;
  }

  /* Title */
  .pnp__headline-event-title {
    margin-bottom: 0rem;
  }

  /* Banner */
  button.trigger {
    cursor: pointer;
  }

  /* Banner > Video */
  .pnp__hero-block{
    min-height: 0 !important;
    padding: 0;
  }
  .pnp__headline_video{
    position: absolute;
    margin-top: 15px;
    top: 0px;
    width: 100%;
    text-align: left;
    h3 {
      margin-left: 20px;
      color: #fff;
    }
  }
  #video-play-icon{
    position: initial;
    height: 8vh;
    width: 8vh;
    background-color: rgba(73, 141, 252, 0.5);
    border-radius: 50%;
  }
  .video-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transform: scale(1.1);
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
  }
  .close-button{
    float: right;
    width: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    cursor: pointer;
    border-radius: 0.25rem;
    background-color: lightgray;
  }
  .close-button:hover {
    background-color: darkgray;
  }
  .video-iframe{
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;
  }
  .show-window {
    opacity: 1;
    visibility: visible;
    transform: scale(1.0);
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
  }
  .hidden-event-map {
    display: none;
  }

  /* Banner > image with map */
  .pnp__headline-event-image {
    overflow: hidden;
    height: 100%;
    @include breakpoint(small only)  {
      margin-bottom: 2rem;
    }
    @include breakpoint(medium) {
      img {
        border-radius: 2rem;
        height: auto;
        position: inherit;
        width: auto;
      }
    }
    .icon-play{
      top: 45%;
      position: absolute;
      width: 91%
    }
  }

  /* Banner > virtual event*/
  .virtual-img {
    border-radius: 2rem;
  }
  .pnp__headline-virtual-event-image{
    .icon-play{
      top: 47%;
      position: absolute;
      width: 100%
    }
  }

  /* Body */
  .pnp__headline-event-body {
    background: #fff;
    margin-top: 0!important;
    padding: 2rem 0!important;
  }
}

/* Mobile */
@media (max-width: 500px) {
  .pnp__fullheadline-event {
    .pnp__headline-event-image{
      button.trigger > img {
        border-radius: 2rem;
      }
    }
    .video-iframe {
      width: 100%;
      height: auto;
    }
    .video-iframe iframe {
      width: 100%;
      height: auto;
    }
    .pnp__video-hero{
      padding: 0;
    }
    .pnp__headline-event-dates {
      padding: 0 1.5rem;
      margin-bottom: 0;
    }
    .icon-play{
      top: 37%!important;
      width: 100%;
    }
    .pnp__headline-event-description p {
      margin-top: 0!important;
    }
    .pnp__headline-virtual-event-image {
      margin-bottom: 0!important;
      padding: 0;
      .pnp__video-hero {
        padding: 0!important;
      }
      .pnp__headline_video {
        top: 10px;
      }
    }

  }
}