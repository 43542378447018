.pnp__checklist {
    padding: 0;
    margin: 0;
    list-style: none;

    background-size: contain;
    background-repeat: no-repeat;

    li {
        padding: 1rem;
        border-bottom: 1px solid $medium-gray;
        font-size: 1.2rem;
    }

    i {
        margin-right: 1.4rem;
        font-size: 1.5rem;
        vertical-align: middle;
    }

    @include breakpoint(small only) {
        background-image: none !important;

        .pnp__half-content-image-inner {
            padding: 2rem;

            h1 {
                font-size: 1.825rem;
            }    
        }
    }
}