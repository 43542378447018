.pnp__team-container {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 0 1rem;

    &.pnp__link-collection-no-top-padding {
        .pnp__link-collection {
            padding: 3rem 0;
        }
    }

    .pnp__link-collection  {
        margin-left: 0;
    }

    a {
        color: $body-font-color;
        height: 100%;
        display: block;
    }

    li {
        margin-bottom: 2rem;
    }

    @include breakpoint (large) {
        border-radius: 2rem;
    }

    .pnp__link-collection-header {
        padding: 5rem 0 1rem;
    }

    .pnp__team-item {
        height: 100%;
        padding: 1.5rem;
        text-align: left;
        margin-bottom: 0;

        .pnp__team-bio {
            display: none;
        }
        
        figure {
            margin: -1.5rem -1.5rem 1.5rem;
            
            img {
                border-top-left-radius: 2rem;
                border-top-right-radius: 2rem;
            }
        }
    }
}
.page-section.team_members {
    background-color: transparent !important;
}
.pnp__team-item-content {
    p {
        line-height: 1.4;
    }
}