// Colours
$foundation-palette: (
  primary: #1796cb,
  secondary: #767676,
  success: #3adb76,
  warning: #ffae00,
  alert: #cc4b37,
);
$body-font-color : #333;


@import 'util/util';

$breakpoints: (
  small: 0,
  medium: 640px,
  large: 970px,
  xlarge: 1100px,
  xxlarge: 1440px,
);

$global-width : rem-calc(1300);
$breakpoint-classes: (small medium large xlarge);

// Fonts
// irodriguez@vbote: replaced DINPro by Protipo
$body-font-family: 'Protipo_pnp', Helvetica, Roboto, Arial, sans-serif;
$header-font-family: 'Protipo_pnp', Helvetica, Roboto, Arial, sans-serif;

$header-styles: (
  small: (
    'h1': ('font-size': 29),
    'h2': ('font-size': 24),
    'h3': ('font-size': 19),
    'h4': ('font-size': 18),
    'h5': ('font-size': 17),
    'h6': ('font-size': 16),
  ),
  medium: (
    'h1': ('font-size': 40),
    'h2': ('font-size': 36),
    'h3': ('font-size': 28),
    'h4': ('font-size': 24),
    'h5': ('font-size': 20),
    'h6': ('font-size': 16),
  ),
);

// Buttons
$button-sizes: (
  tiny: 0.6rem,
  small: 0.75rem,
  default: 1rem,
  large: 1.25rem,
);
$button-padding: 1em 1em;
$button-radius: 0.8rem;
$button-blue-color : #498dfc;
$button-hollow-border-width : 2px;

//irodriguez: added font-weight equivalent to Protipo-Semibold.
$header-font-weight: 600;