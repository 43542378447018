.pnp__dark-scheme {
    background: #10191e;

    h1,h2,h3,h4,h5,h6 {
            color: $white;
    }

    .pnp__card-shadow {
        box-shadow: 0px 0.5rem 0.5rem rgba(0, 0, 0, 0.28);

        &:hover {
            box-shadow: 0px 0.2rem 0.2rem rgba(0, 0, 0, 0.28);
        }
    }

    #pnp__main-footer {
        background: #1b2930;

        h4, a {
            color: white;
        }
    }

    #pnp__header-navigation {
        background: #1b2930;
        border-bottom: 0;
        box-shadow: 0px 5px 10px rgba(9, 15, 17, 0.5);

        a {
            color: #e9e9e9;
        }
    }

    @include breakpoint(small only) {
        .pnp__main-nav,
        .pnp__main-nav.open .pnp__main-navigation {
            background-color: #23353c;
        }
    }

    .pnp__sub-navigation {
        background-color: #23353c;
        box-shadow: 0px 5px 10px rgba(9, 15, 17, 0.5);

        .pnp__sub-nav-header {
            color: #8ebbc1;
        }

        a { color: #8ebbc1; }
    }

    nav:first-child+.page-section .pnp__hero-block,
    .page-section:first-child .pnp__hero-block {
        box-shadow: none;
    }

    .pnp__link-collection-item {
        a, p {
            color: $white !important;
        }

        background-color: #202C32 !important;
        color: $white !important;
    }

    .pnp__text-hero {
        h1, h2 {
            color: #2ffec1;
        }
    }

    .pnp__carousel-arrow {
        background: #17be8a;
        color: white;
    }

    .pnp__image-marquee {
        background: #162127;
        border-color: #2E4147;

        img {
            -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
            opacity: 0.8;
        }
    }

    .button {
        box-shadow: none !important;
    }

    .pnp__checklist {
        color: white;
    }

    .pnp__menu-inner {
        background-color: $white;

        &::before, &::after {
            background-color: $white;
        }
    }
}