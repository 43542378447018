#pnp__location-map {
    height: calc(75vh - 80px - 3rem);

    @include breakpoint(medium) {
        height: calc(97vh - 80px - 3rem);
    }
}
.pnp__location-card {
    margin-bottom: 2rem;
    cursor: pointer;

    h2::before {
        content: '';
        width: 30%;
        height: rem-calc(5);
        background-color: var(--area-color);
        display: block;
        margin: 0 auto 0.8rem;
        border-radius: rem-calc(2.5);
    }

    h2 {
        margin-bottom: 0;
    }

    .pnp__link-collection-item > div {
        height: auto;
        display: block;
    }

    .pnp__location-information {
        display: none;
    }
}
.pnp__link-collection-container .pnp__location-card .pnp__link-collection-item {
    margin: 0;
    padding: 2rem 1.5rem;
}
#pnp__location-map-overlay-container {
    position: absolute;
    top: 50px;
    left:0;
    width: 100%;
    display: none;
}

#pnp__map-location-info {
    padding: 2rem;
    background: rgba(7,19,41, 0.90);
    border-radius: 2rem;
    color: #fff;
}

.pnp__location-information {
     h3, a {
        color: #00ffc0;
    }

    
    .pnp__location-header {
        text-transform: uppercase;
        font-weight: bold;
    }

    @include breakpoint(small only) {
        .pnp__location-programs,
        .pnp__location-w3w {
            display: none;
        }
    }
    
     > section {
        margin-bottom:  1rem;
        word-break: break-word;
        
        &:last-child {
            margin-bottom: 0;
        }
    }

}
.pnp__location-collection {
    padding: 0 0 1.5rem;

    .pnp__link-collection-item {
        height: 100%;
    }

    .pnp__location-icon {
        img {
            width: 46%;
            margin-bottom: 0 !important;
            margin-top: 1rem;
        }
    }
}
#pnp__map-back-container {
    position: absolute;
    top: 1.5rem;
    left: 0;
    right: 0;
    display: none;
    z-index: 1010;

    button {
        width: 3rem;
        height: 3rem;
        background-color: white;
        border-radius: 1.5rem;
        cursor: pointer;
        box-shadow: 0px 0.2rem 0.2rem #000;
        transition: box-shadow 0.2s ease-in;

        &:hover {
            box-shadow: 0 0.1rem 0.1rem #000;
        }  
    }
}