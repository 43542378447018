.pnp__apple-hero {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom center;
    border-radius: 0;
    margin-bottom: 0;

    @include breakpoint(medium) {
        padding-bottom: 60vh;
    }

    .rich-text {
        width: 73%;
        margin: auto;
    }
}